import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import {
	HeaderNotificationsMenu,
	HeaderUserMenu,
	QuickLinks,
	Search,
	ThemeModeSwitcher,
} from '../../../partials';
import { useLayout } from '../../core';
import { useMsal } from '@azure/msal-react';
import { getMyTickets, TicketModel, User } from '../../../../app/services';

const itemClass = 'ms-1 ms-lg-3',
	btnClass = 'btn btn-icon btn-light-primary w-30px h-30px w-md-40px h-md-40px',
	userAvatarClass = 'symbol-30px symbol-md-40px';

const Topbar = ({ user }: { user: User }) => {
	const { config } = useLayout();

	const [ticketAlerts, setTicketAlerts] = useState<TicketModel[]>([])

	useEffect(() => {
		async function getTickets() {
			if (ticketAlerts.length > 0) return
			const tickets: TicketModel[] = await getMyTickets()
			if (tickets) {
				// get only the unread tickets
				const unreadTickets = tickets.filter((ticket) => !ticket.is_read)
				setTicketAlerts(unreadTickets)
			}
		}
		getTickets()
	}, [])

	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>

			{/* begin::Theme mode */}
			<div className={clsx('d-flex align-items-center me-4', itemClass)}>
				<ThemeModeSwitcher toggleBtnClass={btnClass} />
			</div>
			{/* end::Theme mode */}

			{/* NOTIFICATIONS */}
			<div className={clsx('d-flex align-items-center me-8', itemClass)}>
				{/* begin::Menu- wrapper */}
				<div
					className={clsx(btnClass, 'position-relative')}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement='bottom-end'
				>
					<i className='bi bi-bell-fill fs-2' />
					{ticketAlerts.length > 0 && (
						<span className='position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger text-white'>
							{ticketAlerts.length}
						</span>
					)}
				</div>
				<HeaderNotificationsMenu ticketAlerts={ticketAlerts} />
				{/* end::Menu wrapper */}
			</div>
			{/* Quick links */}



			<div className='d-flex align-items-center'>
				{/* begin::Daterangepicker */}
				{/* <button
					onClick={logout}
					className='btn btn-sm btn-bg-danger btn-color-white btn-active-color-gray-200 me-2 d-flex flex-row align-items-center gap-2'
					title='Logout'
					id='kt_dashboard_daterangepicker'
					data-bs-toggle='tooltip'
					data-bs-dismiss='click'
					data-bs-trigger='hover'
				>
					<span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
						Logout
					</span>
					<i className='bi bi-box-arrow-right fs-3'></i>
				</button> */}
				{/* end::Daterangepicker */}

				{/* begin::Actions */}
				{/* <div className='d-flex align-items-center'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light btn-active-color-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
              >
                <KTIcon iconName='add-files' className='fs-2x' />
              </button>
            </div> */}
				{/* end::Actions */}
			</div>

			{/* begin::User */}
			<div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
				<div
					className={clsx('cursor-pointer symbol', userAvatarClass)}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement='bottom-end'
				>
					<img src={user && (user.picturelink ?? toAbsoluteUrl('/media/avatars/300-1.jpg'))} alt='' style={{ objectFit: 'cover' }} />
				</div>
				<HeaderUserMenu user={user} />
			</div>
			{/* end::User */}

			{/* begin::Aside Toggler */}
			{config.header.left === 'menu' && (
				<div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
					<div
						className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
						id='kt_header_menu_mobile_toggle'
					>
						<KTIcon iconName='text-align-left' className='fs-1' />
					</div>
				</div>
			)}
		</div>
	);
};

export { Topbar };

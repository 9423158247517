import { PublicClientApplication } from '@azure/msal-browser'
import { toast } from 'react-toastify'

export async function setupAxios(
	axios: any,
	msalInstance: PublicClientApplication
) {
	axios.defaults.headers.Accept = 'application/json'
	axios.defaults.headers['Content-Type'] = 'application/json'
	axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
	axios.defaults.headers['Access-Control-Allow-Methods'] =
		'GET, POST, PUT, DELETE, OPTIONS'
	axios.interceptors.request.use(
		async (config: { headers: { Authorization: string } }) => {
			const accounts = msalInstance.getAllAccounts()
			const account = accounts[0]
			if (!account) return config

			const tokenRequest = {
				scopes: [
					'profile',
					'openid',
					'offline_access',
					'https://sustevoapp.onmicrosoft.com/6492784c-2ba4-4b41-81e5-22ad55ea6154/user_impersonation',
				],
				account: account,
			}

			let silentToken = null

			try {
				if (accounts[0] && localStorage.getItem('token')) {
					silentToken = {
						accessToken: localStorage.getItem('token'),
					}
				} else {
					silentToken = await msalInstance.acquireTokenSilent(
						tokenRequest
					)
					if (!silentToken) {
						silentToken = await msalInstance.acquireTokenPopup(
							tokenRequest
						)
					}
				}
			} catch (err) {
				console.log(err)
			}

			config.headers.Authorization = `Bearer ${silentToken?.accessToken}`

			// save token to local storage
			localStorage.setItem('token', silentToken?.accessToken || '')

			return config
		},
		(err: any) => Promise.reject(err)
	)

	axios.interceptors.response.use(
		(response: any) => response,
		async (error: any) => {
			const status = error.response.status
			// remove token from local storage if unauthorized
			if (status === 401) {
				localStorage.removeItem('token')
				toast.error('Unauthorized, checking for new token...')
				try {
					const tokenResponse = await msalInstance.acquireTokenSilent({
						scopes: [
							'profile',
							'openid',
							'offline_access',
							'https://sustevoapp.onmicrosoft.com/6492784c-2ba4-4b41-81e5-22ad55ea6154/user_impersonation',
						],
					})
					localStorage.setItem('token', tokenResponse.accessToken)
					toast.success('New token received')
					// retry request
					error.config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
					return axios.request(error.config)
				} catch (silentError) {
					console.log(silentError)
					msalInstance.acquireTokenRedirect({
						scopes: [
							'profile',
							'openid',
							'offline_access',
							'https://sustevoapp.onmicrosoft.com/6492784c-2ba4-4b41-81e5-22ad55ea6154/user_impersonation',
						],
					})
				}
			}
			toast.dismiss()
			if (status === 500) {
				toast.error('Internal server error')
			} else if (status === 404) {
				//toast.error('Not found')
			} else if (status === 403) {
				toast.error('Forbidden')
			} else if (status === 400) {
				toast.error('Bad request')
			} else if (status === 422) {
				toast.error('Unprocessable content')
			} else {
				toast.error('An error occurred')
			}
			console.log(error)
			return Promise.reject(error)
		}
	)
}

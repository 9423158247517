import axios from 'axios'
import config from '../utils/config'
import { Client } from './AdminService'

export interface User {
	azure_object_id: string
	name: string
	email: string
	client_id: number
	client_abbreviation: string
	role: string
	id: number
	picturelink?: string
	client?: Client
}

const user = `${config.apiKey}/users/`

const client = `${config.apiKey}/clients/`

export async function getUser() {
	return (await axios.get<User>(user + 'myself/')).data
}

export async function getUserById(id: number) {
	return (await axios.get<User>(user + id + '/')).data
}

export async function updateMyself(data: Partial<User>) {
	return (await axios.patch<User>(user + 'myself/', data)).data
}

export async function getMyClient(client_id: number) {
	return (await axios.get<Client>(client + client_id + '/')).data
}
import axios from 'axios'
import config from '../utils/config'
import { User } from './UserService'

export const ticket = config.apiKey + '/tickets/'

export interface TicketModel {
	id: number
	user_id: number
	ticket_type: string
	issue: string
	uploaded_image: string
	admin_id: number
	response: string
	status: string
	created_at: string
	updated_at: string
	is_read: boolean
}

export interface MessageModel {
	user_id: number;
	ticket_id: number;
	content: string;
	created_at: string;
	user: User;
	id: number;
}

export async function getMyTickets() {
	return (await axios.get<TicketModel[]>(ticket + 'mine/')).data
}

export async function getTicket(id: number) {
	return (await axios.get<TicketModel>(ticket + id + '/')).data
}

export async function createTicket(data: Partial<TicketModel>, file?: File) {
	const formData = new FormData();
	formData.append('ticket_type', data.ticket_type || '');
	formData.append('issue', data.issue || '');
	formData.append('status', data.status || '');
	if (file) formData.append('file', file);

	return (await axios.post<TicketModel>(ticket, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})).data;
}

export async function getMessages(id: number) {
	return (await axios.get<MessageModel[]>(ticket + id + '/messages/')).data
}

export async function createMessage(id: string, content: string) {
	return (await axios.post<MessageModel>(ticket + id + '/messages/', { content })).data
}